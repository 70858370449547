import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useLocaleContext } from 'application/contexts/LocaleContext';
import {
	articleInfoColumnMapper,
	linkMapper,
	newsCardMapper,
	newsPreviewMapper,
	newsWithTagsCardMapper,
	newsWithTagsPreviewMapper,
} from 'helpers/mappers';
import { useState } from 'react';
import { ThreeColumnSpot } from 'ui/components/3-organisms/ThreeColumnSpot/ThreeColumnSpot';

export const ThreeColumnSpotFeature: React.FC<Content.ThreeColumnSpot> = ({ content }) => {
	const { heading, color, link, items } = content?.properties;
	const locale = useLocaleContext();
	const [activeTag] = useState<Tags.Tag | null>(null);
	const dictionary = useDictionaryContext();

	const allTranslation = dictionary.getValue('All');

	const previewCardItems = items?.map((item) => {
		if (item.documentType === 'articlePage') {
			return {
				headline: item.properties.mainHeading,
				link: {
					url: item.url,
				},
				text: item.properties.teaser,
				image: {
					url: item.properties.image?.url,
					altText: item.properties.image?.properties?.altText,
					focalPoint: item.properties.image?.focalPoint,
				},
				featuredImage: {
					url: item.properties.featuredImage?.url,
					altText: item.properties.featuredImage?.properties?.altText,
					focalPoint: item.properties.featuredImage?.focalPoint,
				},
				tags: {
					first: item.properties.location,
					second: `${articleInfoColumnMapper(item.properties.articleInfo[0]).line} ${
						articleInfoColumnMapper(item.properties.articleInfo[0]).line2
					}`,
				},
			};
		}

		if (item.documentType === 'newsPage') {
			const newsCard = newsCardMapper(item);
			return newsPreviewMapper({ ...newsCard, date: locale.formatStringDate(newsCard.date) });
		}
		if (item.documentType === 'newsPageWithTags') {
			const newsCard = newsWithTagsCardMapper(item);
			return newsWithTagsPreviewMapper({ ...newsCard, date: locale.formatStringDate(newsCard.date) }, activeTag, allTranslation);
		}
	});

	return <ThreeColumnSpot bgColor={color} heading={heading} items={previewCardItems} ctaLink={linkMapper(link)} />;
};
