import classNames from 'classnames';
import styles from './ThreeColumnSpot.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Link, LinkProps } from 'ui/components/1-atoms/Action/Link/Link';
import { PreviewCardList } from 'ui/components/3-organisms/PreviewCardList/PreviewCardList';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { PreviewCardProps } from 'ui/components/2-molecules/Card/PreviewCard/PreviewCard';
import ColorOptions from 'helpers/types';
export interface ThreeColumnSpotProps {
	className?: string;
	heading?: string;
	ctaLink?: LinkProps;
	items?: PreviewCardProps[];
	bgColor?: ColorOptions;
}

export const ThreeColumnSpot: React.FC<ThreeColumnSpotProps> = ({ className, heading, ctaLink, items, bgColor = 'white' }) => {
	return (
		<div className={classNames(styles.ThreeColumnSpot, styles[`u-color___${bgColor}`], className)}>
			<Container width="Standard">
				<div className={classNames(styles.ThreeColumnSpot_content)}>
					{heading && (
						<Heading className={styles.ThreeColumnSpot_heading} headingLevel="h2" style="md">
							{heading}
						</Heading>
					)}

					{ctaLink && (
						<Link iconLink={true} className={styles.ThreeColumnSpot_ctaLink} href={ctaLink?.href}>
							{ctaLink?.children}
						</Link>
					)}

					{items && (
						<div className={styles.ThreeColumnSpot_articles}>
							<PreviewCardList items={items} />
						</div>
					)}
				</div>
			</Container>
		</div>
	);
};
